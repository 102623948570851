<template>
  <div>
    <!-- picD8F35FA9FC09B1BA523ED34F51F270BB.jpg -->
    <img class="rounded img-large" src="@/assets/news-network/signup-for-closed-beta.jpg">
    <p>
      LEGO Universe has entered Closed BETA, one of the last development stages before launch!
    </p>
    <p>
      Help us power up the game's performance: <router-link to="/beta">Sign up to become a LEGO Universe BETA Tester now!</router-link>
    </p>
    <p>
      If chosen, you could become one of the first LEGO minifigures to play and build in the first Massively Multiplayer Online Game (MMOG) for LEGO fans!
    </p>
  </div>
</template>
